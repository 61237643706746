import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../context/useCases"
import { FiledType, Form, Field } from "../../components/form"
import { Payment } from "../../../domain/payment/payment"
import { ReportOperatorPaymentsOfMonth } from "../../../repository/api/generated"


export const PaymentModal = (props: { salary: number, operatorId: UniqueId, operatorName: string, periodString: JSX.Element, from: string, end: string, close: () => void, closeUpdate: (reportItem: ReportOperatorPaymentsOfMonth) => void }) => {

    let useCases = useContext(UseCasesContext)

    const convertStartDateToLocalTime = (input: Date) => {
        let date = new Date(input)

        if (date.getDate() !== 1) {
            date.setHours(date.getHours() - 3)
        }
        return date
    }

    const convertEndDateToLocalTime = (input: string) => {
        let date = new Date(input.split("T")[0])
        date.setHours(23)
        date.setMinutes(59)
        return date
    }

    const [form, setForm] = useState<
        {
            operatorId: string,
            start: Date,
            end: Date,
            studyAvans: boolean,
            avans: number,
            workedHours: number,
            penalty: number,
            mainBonus: number,
            scoreboardBonus: number,
            groupBonus: number,
            changeSalary: number,
            bank: string,
            processedPayments: string[],
            confirmPayment: boolean,
            salary: number,
        }>(
            {
                operatorId: props.operatorId,
                start: convertStartDateToLocalTime(new Date(props.from!)),
                end: convertEndDateToLocalTime(props.end!),
                studyAvans: false,
                avans: 0,
                workedHours: 0,
                penalty: 0,
                mainBonus: 0,
                scoreboardBonus: 0,
                groupBonus: 0,
                changeSalary: 0,
                bank: "",
                processedPayments: [],
                confirmPayment: false,
                salary: 0,
            }
        )

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        useCases?.paymentsUseCase.CreateOrUpdatePayment(form.operatorId, form.start, form.end, form.studyAvans, form.avans, form.workedHours, form.penalty, form.mainBonus, form.scoreboardBonus, form.groupBonus, form.bank, form.processedPayments, form.confirmPayment, form.changeSalary, props.closeUpdate)
    }

    useEffect(() => {
        console.log('end', props.end)
        useCases?.paymentsUseCase.ReadPaymentForOperatorInrange(props.operatorId, convertStartDateToLocalTime(new Date(props.from)), convertEndDateToLocalTime(props.end!)).then((response) => {
            if (response instanceof Error) {
                console.log(response.message.indexOf("no rows") !== -1)
                console.log('request operator')
            } else {
                setForm({
                    ...form,
                    studyAvans: response.StudyAvans()!,
                    avans: response.Avans()!,
                    workedHours: response.WorkedHours()!,
                    penalty: response.Penalty()!,
                    mainBonus: response.MainBonus()!,
                    scoreboardBonus: response.ScoreboardBonus()!,
                    groupBonus: response.GroupBonus()!,
                    bank: response.Bank()!,
                    processedPayments: response.ProcessedPayments()!,
                    confirmPayment: response.ConfirmPayment(),
                    changeSalary: response.ChangeSalary(),
                    salary: response.Salary() ? response.Salary() : props.salary,
                })
            }
        })
    }, [])

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const fieldsList: Field[] = [
        {
            name: "studyAvans",
            title: "Аванс обучение",
            placeholder: "Аванс обучение",
            value: form.studyAvans,
            type: FiledType.Checkbox,
        },
        {
            name: "avans",
            title: "Аванс",
            placeholder: "Укажите аванс",
            value: form.avans,
            type: FiledType.Number,
        },
        // {
        //     name: "workedHours",
        //     title: "Отработанные часы за месяц",
        //     placeholder: "Укажите кол-во часов",
        //     value: form.workedHours,
        //     type: FiledType.Number,
        // },
        {
            name: "penalty",
            title: "Штрафы, руб",
            placeholder: "Укажите Штрафы",
            value: form.penalty,
            type: FiledType.Number,
        },

        {
            name: "mainBonus",
            title: "Премия основная, руб.",
            placeholder: "Укажите Основую премию",
            value: form.mainBonus,
            type: FiledType.Number,
        },

        {
            name: "scoreboardBonus",
            title: "Премия Табло, руб.",
            placeholder: "Укажите премию Табло",
            value: form.scoreboardBonus,
            type: FiledType.Number,
        },

        {
            name: "groupBonus",
            title: "Премия группа, руб.",
            placeholder: "Укажите премию группа",
            value: form.groupBonus,
            type: FiledType.Number,
        },

        {
            name: "changeSalary",
            title: "Оклад",
            placeholder: "Оклад",
            disabled: form.processedPayments.includes('salary'),
            value: form.changeSalary ? form.changeSalary : form.salary,
            type: FiledType.Number,
        },

        {
            name: "confirmPayment",
            title: "Оклад подтвежден",
            placeholder: "Оклад подтвежден",
            value: form.confirmPayment,
            disabled: form.processedPayments.includes('salary') || !form.changeSalary,
            type: FiledType.Checkbox,
        },

        {
            name: "bank",
            title: "Банк",
            placeholder: "Укажите банк",
            value: form.bank,
            type: FiledType.Select,
            options: [
                {
                    title: "Сбербанк",
                    value: "sberbank"
                },
                {
                    title: "Тинькофф",
                    value: "tinkoff"
                },
                {
                    title: "Другой",
                    value: "other"
                },
            ],
        },

        {
            name: "processedPayments",
            title: "Оплаты",
            placeholder: "Укажите оплаты",
            value: form.processedPayments,
            type: FiledType.MultiSelect,
            options: [
                {
                    title: "Аванс обучение",
                    value: "study_avans"
                },
                {
                    title: "Оклад",
                    value: "salary"
                },
                {
                    title: "Премия основная",
                    value: "main_bonus"
                },
                {
                    title: "Премия Табло",
                    value: "scoreboard_bonus"
                },
                {
                    title: "Премия группа",
                    value: "group_bonus"
                },
                {
                    title: "Аванс",
                    value: "avans"
                },
            ],
        },
    ]

    const getEditableFieldsForRgAndRkc = ()  => {
        let fields: Field[] = []
        
        let dontShowFields: string[] = ["processedPayments"]
        
        if (form.processedPayments.includes("avans")) {
            dontShowFields.push("avans")
        }

        if (form.processedPayments.includes("study_avans")) {
            dontShowFields.push("studyAvans")
        }

        if (form.processedPayments.includes("main_bonus")) {
            dontShowFields.push("mainBonus")
        }

        if (form.processedPayments.includes("scoreboard_bonus")) {
            dontShowFields.push("scoreboardBonus")
        }

        if (form.processedPayments.includes("group_bonus")) {
            dontShowFields.push("groupBonus")
        }

        if (form.processedPayments.includes("salary")) {
            dontShowFields.push("workedHours")
            dontShowFields.push("penalty")
        }

        if (useCases?.authUseCase.IsHeadOfContactCenter()) {
            dontShowFields.push("studyAvans", "avans", "bank")
        } else if (useCases?.authUseCase.IsHeadOfGroup()) {
            dontShowFields.push("avans", "bank")
        }

        if (!useCases?.authUseCase.IsHeadOfContactCenter()) {
            dontShowFields.push("confirmPayment")
        }

        fieldsList.forEach((field) => {
            if (!dontShowFields.includes(field.name)) {
                fields.push(field)
            }
        })

        return fields
    }


    return <div className="modal fade show" id="modalCenter" style={{ display: "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalCenterTitle">Карточка оператора</h5>

                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.close() }}></button>
                </div>
                <div className="modal-body">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <b>{props.operatorName}</b>
                        {props.periodString}
                    </div>
                    <Form
                        state={{
                            loading: false,
                            error: "",
                        }}

                        submit={submit}

                        fields={useCases?.authUseCase.IsAdminUser()?fieldsList:getEditableFieldsForRgAndRkc()}
                        btnSmall={true}
                        submitBtnTitle={"Сохранить"}
                        updateForm={updateForm}
                    />
                </div>

            </div>
        </div>
    </div>
}