import { Payment } from "../../domain/payment/payment";
import { ReportOperatorPaymentsOfMonth } from "../../repository/api/generated";
import { PaymentsRepository } from "../../repository/api/payment/payment";
import { actionCreators } from "../../state";

export class PaymentUseCases {
    private api: PaymentsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: PaymentsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadPaymentForOperatorInrange = async (operatorId: string, from: Date, to: Date) => {
        let response = await this.api.ReadPaymentForOperatorInrange(operatorId, from, to)
        return response
    }

    public CreateOrUpdatePayment = async (operatorId: string, start: Date, end: Date, avansStudy: boolean, avans: number, workedHours: number, penalty: number, mainBonus: number, scoreboardBonus: number, groupBonus: number, bank: string, processedPayments: string[], confirmPayment: boolean, changeSalary: number, callback?: (reportItem: ReportOperatorPaymentsOfMonth)=>void) => {
        this.stateActions.PaymentCreateRequest()
        let response = await this.api.CreateOrUpdatePayment(operatorId, start, end, avansStudy, avans, workedHours, penalty, mainBonus, scoreboardBonus, groupBonus, bank, processedPayments, confirmPayment, changeSalary)
        if (response instanceof Error) {
            this.stateActions.PaymentCreateError(response.message)
            return
        } else {
            //this.stateActions.PaymentCreateSuccess(response)
            if (callback) {
                callback(response)
            }
        }
    }

    public SetPaymentProcessedByOperatorIdAndDateRange = async (operatorId: UniqueId, from: Date, to: Date, payementType: string) => {
        let response = await this.api.SetPaymentProcessedByOperatorIdAndDateRange(operatorId, from, to, payementType)
        return response
    }

    public ImportBonusesFromXlsx = async (file: File) => {
        let response = await this.api.ImportBonusesFromXlsx(file)
        return response
    }

}