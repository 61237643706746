import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { ReportOperatorPaymentsOfMonth } from "../../../repository/api/generated";
import { UseCasesContext } from "../../../context/useCases";
import { PaymentModal } from "./payment-modal";
import { Payment } from "../../../domain/payment/payment";

function useOutsideAlerter(ref: any, callBack: () => void) {
    useEffect(() => {

        function handleClickOutside(event: any) {
            if (ref?.current && !ref?.current.contains(event.target)) {
                callBack()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [ref]);
}

export const TableRowNew = (props: { payment: ReportOperatorPaymentsOfMonth, updateReportItemsPaymentItem: (reportItem: ReportOperatorPaymentsOfMonth) => void, last: boolean }) => {
    const [showModal, setShowModal] = useState(false)

    const rowRef = useRef<any>(null);
    let useCases = useContext(UseCasesContext)

    const [payment, setPayment] = useState<ReportOperatorPaymentsOfMonth>()

    useEffect(()=>{
        setPayment(props.payment)
    },[props.payment.operator?.id])

    const formatDate = (day: Date) => {
        if (day.getFullYear() === 1) {
            return ""
        }
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2);
    }

    useOutsideAlerter(rowRef, () => {
        //setShowModal(false)
    });

    const formatDateMonthAndYear = (input: string) => {
        let monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
        let date = new Date(input)
        return <>{monthNames[date.getMonth()]}<br /> {date.getFullYear()}</>
    }

    const pickRole = (input: string) => {
        switch (input) {
            case "operator":
                return "Оператор"
            case "ayp":
                return "АУП"
        }
    }

    const convertStartDateToLocalTime = (input: Date) => {
        let date = new Date(input)

        if (date.getDate() !== 1) {
            date.setHours(date.getHours() - 3)
        }
        return date
    }

    const convertEndDateToLocalTime = (input: Date) => {
        let date = new Date(input)
        if (date.getDate() == 1) {
            date.setHours(date.getHours() - 3)
        }
        return date
    }

    const setPaymentProcessed = async (type: string) => {
        let response = await useCases?.paymentsUseCase.SetPaymentProcessedByOperatorIdAndDateRange(props.payment.operator?.id!, convertStartDateToLocalTime(new Date(props.payment.date_range?.start!)), convertEndDateToLocalTime(new Date(props.payment.date_range?.end!)), type)
        if (response instanceof Error) {

        } else {
            props.updateReportItemsPaymentItem(response!)
          
            
            updateItemWithPayment(response!)
        }
    }

    const updateItemWithPayment = (reportItem: ReportOperatorPaymentsOfMonth) => {
        setPayment(reportItem)
    }

    const drawCellWithButton = (type: string, value: number) => {
        if (value == 0) {
            return <div className="css_sd css_sd_nowrap" >{value!}</div>
        }

        if (payment!.processed_payments?.includes(type)) {
            return <div className="css_sd css_sd_nowrap" style={{backgroundColor: "#dde8cb" }}>{value!}</div>
        }

        if (type === 'salary' && payment!.change_salary) {
            return <div className="css_sd css_sd_nowrap" style={{backgroundColor: "#fdec7a" }}>{value!}</div>
        }

        return <div className="css_sd css_sd_nowrap" style={{backgroundColor: "#ffd7d7"}}>{value!} <br /> <a href="#" onClick={(e) => { e.preventDefault(); setPaymentProcessed(type) }}>Оплатить</a></div>
    }

    const colorByValueCell = (value: number) => {
        if (value == 0) {
            return <div className="css_sd css_sd_nowrap" >{value!}</div>
        }

        return <div className="css_sd css_sd_nowrap" style={{backgroundColor: "#ffd7d7" }}>{value!}</div>
    }

    const pickBank = (input: string) => {
        switch (input) {
            case "sberbank":
                return "Сбербанк"
            case "tinkoff":
                return "Тинькофф"
            case "other":
                return "Другой"
        }
    }

    if (!payment) {
        return <></>
    }
    return (
        <Fragment key={payment!.operator?.id}>
            <div key={payment!.operator?.id} className="css_tr" ref={rowRef} >
                <div className="css_sd css_sd_nowrap">{formatDateMonthAndYear(payment!.date_range?.start!)}</div>
                <div className="css_sd css_sd_nowrap"><a href="#" onClick={(e) => { e.preventDefault(); setShowModal(true) }}>{payment!.operator?.name}</a></div>
                <div className="css_sd css_sd_nowrap">{pickRole(payment!.operator?.role!)}</div>
                <div className="css_sd css_sd_nowrap">{payment!.operator?.role == "ayp" ? "Апанасенко Айна": payment!.operator?.responsible}</div>
                {useCases?.authUseCase.IsAdminUser()?<div className="css_sd css_sd_nowrap">{pickBank(payment!.bank!)}</div>:<></>}
                <div className="css_sd css_sd_nowrap">{formatDate(new Date(payment!.operator?.state_date!))}</div>
                <div className="css_sd css_sd_nowrap">{Math.round(payment!.work_stage! * 100) / 100}</div>
                <div className="css_sd css_sd_nowrap">{payment!.factor!}</div>
                {drawCellWithButton("study_avans", payment!.avans_study!)}
                <div className="css_sd css_sd_nowrap">{payment!.worked_hours!}</div>
                {useCases?.authUseCase.IsAdminUser()
                || useCases?.authUseCase.IsHeadOfContactCenter()
                || useCases?.authUseCase.IsHeadOfGroup()
                    ? drawCellWithButton("salary", payment!.salary!)
                    : <></>}
                {drawCellWithButton("main_bonus", payment!.main_bonus!)}
                {drawCellWithButton("scoreboard_bonus", payment!.scoreboard_bonus!)}
                {drawCellWithButton("group_bonus", payment!.group_bonus!)}
                <div className="css_sd css_sd_nowrap">{payment!.penalty!}</div>
                <div className="css_sd css_sd_nowrap">{payment!.total_earned_sum!}</div>
                {drawCellWithButton("avans", payment!.avans!)}
                <div className="css_sd css_sd_nowrap">{payment!.total_paid_sum_without_avans!}</div>
                {colorByValueCell(payment!.remaining_sum!)}
            </div>
            {showModal && ! useCases?.authUseCase.IsHr() ?<PaymentModal salary={Number(payment!.salary)} operatorId={payment!.operator?.id!} operatorName={payment!.operator?.name!} periodString={formatDateMonthAndYear(payment!.date_range?.start!)!} from={payment!.date_range?.start!} end={payment!.date_range?.end!} closeUpdate={(reportItem: ReportOperatorPaymentsOfMonth)=>{setShowModal(false); updateItemWithPayment(reportItem)}} close={()=>{setShowModal(false)}} /> :<></>}
        </Fragment>
    )
}
