import { Fragment, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Menu } from "../../components/menu"
import { ChevronDown, ChevronUp } from "tabler-icons-react"
import { UseCasesContext } from "../../../context/useCases";
import { ReportOperatorPaymentsOfMonth } from "../../../repository/api/generated";
import Select from "react-select";
import { TableRowNew } from "./tableRow";
import { Payment } from "../../../domain/payment/payment";
import { ImportModal } from "./import-modal";

export const Report = () => {
    let { id } = useParams();

    const [operatorOptions, setOperatorOptions] = useState<{ value: UniqueId, label: string }[]>([{ value: "", label: "Выберите оператора" }])
    const [responsibleOptions, setResponsibleOptions] = useState<{ value: number, label: string }[]>([{ value: 0, label: "Выберите ответственного" }])
    const [scrollInterval, setScrollInterval] = useState<NodeJS.Timer | null>()
    const [form, setForm] = useState<{
        from: Date,
        to: Date,
        operatorId?: string | undefined,
        bank?: string,
        role?: string,
        responsibleId?: number,
    }>({
        from: new Date(new Date().getFullYear(), new Date().getMonth()-1, 1),
        to: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
        bank: "",
        role: "",
        responsibleId: 0,
    })

    const [impExDropDownShow, setImportExportDropDownShow] = useState<boolean> (false)
    const [importModalShow, setImportModalShow] = useState<boolean>(false)

    const [paymentsItems, setPaymentsItems] = useState<ReportOperatorPaymentsOfMonth[]>([])

    let useCases = useContext(UseCasesContext)

    const updateReportItemsPaymentItem = (inputItem: ReportOperatorPaymentsOfMonth) => {
        let newItems = []

        for (let index = 0; index < paymentsItems.length; index++) {
            let reportItem = paymentsItems[index];
            if (reportItem.operator?.id == inputItem.operator?.id! && compareDates(new Date(reportItem.date_range?.start!), new Date(inputItem.date_range?.start!)) && compareDates(new Date(reportItem.date_range?.end!), new Date(inputItem.date_range?.end!))) {
                reportItem = inputItem!
            }
            newItems.push(reportItem)

        }

        setPaymentsItems([...newItems])
    }

    const compareDates = (first: Date, second: Date): boolean => {
        return first.getFullYear() == second.getFullYear() && first.getDay() == second.getDay() && first.getMonth() == second.getMonth()
    }

    const readResponsibles = async () => {
        let response = await useCases?.reportUseCase.ReadResponsiblesForSearch()

        if (response instanceof Error) {

        } else {
            console.log(response)
            let newResponsibleOptions: { value: number, label: string }[] = [{ value: 0, label: "Выберите ответственного" }, { value: -1, label: "Апанасенко Айна" } ]
            response!.forEach((pi) => {
                newResponsibleOptions.push({ value: pi.id!, label: pi.name! })
            })

            setResponsibleOptions([...newResponsibleOptions])
        }
    }


    useEffect(() => {
        useCases?.reportUseCase.ReadReport(form.from, form.to, form.operatorId, form.bank, form.role, form.responsibleId).then((response) => {

            if (response instanceof Error) {

            } else {
                setPaymentsItems(response!.payments!)
            }

        })

        useCases?.reportUseCase.ReadOperatorsForSearch(form.from, form.to).then((response) => {
            if (response instanceof Error) {

            } else {
                console.log(response)
                let newOperatorOptions: { value: UniqueId, label: string }[] = [{ value: "", label: "Выберите оператора" }]
                response.forEach((pi) => {
                    newOperatorOptions.push({ value: pi.id!, label: pi.name! })
                })

                setOperatorOptions([...newOperatorOptions])
            }
        })
    }, [form])

    useEffect(()=>{
        readResponsibles()
    },[])

    const downloadReportXlsx = async (e: any) => {
        e.preventDefault()
        if (form) {
            let file = await useCases?.reportUseCase.ReadReportXlsx(form.from, form.to, form.operatorId, form.bank, form.role)

            if (file instanceof Error) {

            } else {

                var link = document.createElement('a');
                link.href = process.env.REACT_APP_BACKEND_URL + "/api/files/" + "tmp.xlsx";
                link.target = "_blank"
                //link.download = "result.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        setImportExportDropDownShow(false);
    }

    const downloadTinkoffZp = async (e: any) => {
        e.preventDefault()
        if (form) {
            let file = await useCases?.reportUseCase.ReadTinkoffZp(form.from, form.to, form.operatorId, form.bank, form.role)

            if (file instanceof Error) {

            } else {

                var link = document.createElement('a');
                link.href = process.env.REACT_APP_BACKEND_URL + "/api/files/" + "tinkoff.xlsx";
                link.target = "_blank"
                //link.download = "result.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        setImportExportDropDownShow(false);
    }

    const downloadSberZp = async (e: any) => {
        e.preventDefault()
        if (form) {
            let file = await useCases?.reportUseCase.ReadSberZp(form.from, form.to, form.operatorId, form.bank, form.role)

            if (file instanceof Error) {

            } else {

                var link = document.createElement('a');
                link.href = process.env.REACT_APP_BACKEND_URL + "/api/files/" + "sber.csv";
                link.target = "_blank"
                link.download = "sber.csv";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        setImportExportDropDownShow(false);
    }

    const scrollDirection = (direction: string) => {
        switch (direction) {
            case "left":
                let timerIdLeft = setInterval(() => {
                    if (document.querySelector('.table_scroll')! && document.querySelector('.table_scroll')!.scrollLeft > 0) {
                        document.querySelector('.table_scroll')!.scroll(document.querySelector('.table_scroll')!.scrollLeft - 25, document.querySelector('.table_scroll')!.scrollTop)
                    }
                }, 20);
                setScrollInterval(timerIdLeft)
                break
            case "right":
                let timerIdRight = setInterval(() => {
                    if (document.querySelector('.table_scroll')!) {
                        document.querySelector('.table_scroll')!.scroll(document.querySelector('.table_scroll')!.scrollLeft + 25, document.querySelector('.table_scroll')!.scrollTop)
                    }
                }, 20);
                setScrollInterval(timerIdRight)
                break
        }
    }

    const clearScroll = () => {
        if (scrollInterval) {
            clearInterval(scrollInterval!)
        }
    }





    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4">Зарплата операторов</h4>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="btn-group">
                                    <button type="button" onClick={()=>{setImportExportDropDownShow(!impExDropDownShow)}} className={impExDropDownShow?"btn btn-primary dropdown-toggle waves-effect waves-light show":"btn btn-primary dropdown-toggle waves-effect waves-light"} data-bs-toggle="dropdown" aria-expanded="false">
                                        Импорт/Экспорт
                                    </button>
                                    <ul className={impExDropDownShow?"dropdown-menu show":"dropdown-menu"} style={{top:"40px"}}>
                                        <li><a className="dropdown-item" href="#" onClick={downloadReportXlsx}>Выгрузить в Excel</a></li>
                                        {useCases?.authUseCase.IsAdminUser()?<li><a className="dropdown-item" href="#" onClick={downloadSberZp}>Выгрузить для Сбербанка</a></li>:<></>}
                                        {useCases?.authUseCase.IsAdminUser()?<li><a className="dropdown-item" href="#" onClick={downloadTinkoffZp}>Выгрузить для Тинькофф</a></li>:<></>}
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        {useCases?.authUseCase.IsAdminUser() || useCases?.authUseCase.IsHeadOfContactCenter()?<li><a className="dropdown-item" href="#" onClick={(e)=>{e.preventDefault(); setImportExportDropDownShow(false); setImportModalShow(true)}}>Импорт премий</a></li>:<></>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-4" style={{ zIndex: 800 }}>
                            <div className="card-body">
                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <label className="form-label" htmlFor="multicol-cityname">Оператор</label>
                                        <Select options={operatorOptions} placeholder={"Выберите оператора"} defaultValue={operatorOptions[0]} onChange={(newValue): void => {
                                            let id: UniqueId
                                            operatorOptions.forEach((val: any) => {
                                                if (val == newValue) {
                                                    id = val["value"]
                                                }
                                            })
                                            try {
                                                if (form) {
                                                    setForm({
                                                        ...form,
                                                        operatorId: id!
                                                    })
                                                }
                                            } catch (e) { }
                                        }} name="cityId" id="multicol-cityname" />
                                    </div>
                                    {!useCases?.authUseCase.IsHeadOfGroup() ?<div className="col-md-4">
                                        <label className="form-label" htmlFor="multicol-cityname">Ответственный</label>
                                        <Select options={responsibleOptions} placeholder={"Выберите ответственного"} defaultValue={responsibleOptions[0]} onChange={(newValue): void => {
                                            let id: number
                                            responsibleOptions.forEach((val: any) => {
                                                if (val == newValue) {
                                                    id = val["value"]
                                                }
                                            })
                                            try {
                                                if (form) {
                                                    setForm({
                                                        ...form,
                                                        responsibleId: id!
                                                    })
                                                }
                                            } catch (e) { }
                                        }} name="cityId" id="multicol-cityname" />
                                    </div>:<></>}
                                    {useCases?.authUseCase.IsAdminUser() ?<div className="col-md-4">
                                        <label className="form-label" htmlFor="multicol-cityname">Банк</label>
                                        <select value={form.bank} onChange={(e) => {
                                            setForm({
                                                ...form,
                                                bank: e.target.value,
                                            })
                                        }} name={"bank"} className="form-select" >
                                            <option selected value="">{"Все банки"}</option>
                                            <option value="sberbank">{"Сбербанк"}</option>
                                            <option value="tinkoff">{"Тинькофф"}</option>
                                            <option value="other">{"Другой"}</option>
                                        </select>
                                    </div>:<></>}
                                    <div className="col-md-4">
                                        <label className="form-label" htmlFor="multicol-email">Дата с</label>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-group">
                                                    <select value={form.from.getFullYear()} onChange={(e) => {
                                                        let newDate = form.from
                                                        newDate.setFullYear(parseInt(e.target.value))
                                                        setForm({
                                                            ...form,
                                                            from: newDate
                                                        })
                                                    }} name={"from_year"} className="form-select" >
                                                        <option disabled selected value="0">{"Выберите год"}</option>
                                                        <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                                                        <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                                        <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                                                    </select>
                                                    <select value={form.from.getMonth()} onChange={(e) => {
                                                        let newDate = form.from
                                                        newDate.setMonth(parseInt(e.target.value))
                                                        setForm({
                                                            ...form,
                                                            from: newDate
                                                        })
                                                    }} name={"from_month"} className="form-select" >
                                                        <option value="0">Январь</option>
                                                        <option value="1">Февраль</option>
                                                        <option value="2">Март</option>
                                                        <option value="3">Апрель</option>
                                                        <option value="4">Май</option>
                                                        <option value="5">Июнь</option>
                                                        <option value="6">Июль</option>
                                                        <option value="7">Август</option>
                                                        <option value="8">Сентябрь</option>
                                                        <option value="9">Октябрь</option>
                                                        <option value="10">Ноябрь</option>
                                                        <option value="11">Декабрь</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label" htmlFor="multicol-email">По</label>
                                        <div className="row">
                                            <div className="input-group">
                                                <select value={form.to.getFullYear()} onChange={(e) => {
                                                    let newDate = form.to
                                                    newDate.setFullYear(parseInt(e.target.value))
                                                    setForm({
                                                        ...form,
                                                        to: newDate
                                                    })
                                                }} name={"from_year"} className="form-select" >
                                                    <option disabled selected value="0">{"Выберите год"}</option>
                                                    <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                                                    <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                                    <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                                                </select>
                                                <select value={form.to.getMonth()} onChange={(e) => {
                                                    let newDate = form.to
                                                    newDate.setMonth(parseInt(e.target.value))
                                                    setForm({
                                                        ...form,
                                                        to: newDate
                                                    })
                                                }} name={"from_month"} className="form-select" >
                                                    <option value="0">Январь</option>
                                                    <option value="1">Февраль</option>
                                                    <option value="2">Март</option>
                                                    <option value="3">Апрель</option>
                                                    <option value="4">Май</option>
                                                    <option value="5">Июнь</option>
                                                    <option value="6">Июль</option>
                                                    <option value="7">Август</option>
                                                    <option value="8">Сентябрь</option>
                                                    <option value="9">Октябрь</option>
                                                    <option value="10">Ноябрь</option>
                                                    <option value="11">Декабрь</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {!useCases?.authUseCase.IsHeadOfGroup() ?<div className="col-md-4">
                                        <label className="form-label" htmlFor="multicol-cityname">Роль</label>
                                        <select value={form.role} onChange={(e) => {
                                            setForm({
                                                ...form,
                                                role: e.target.value,
                                            })
                                        }} name={"role"} className="form-select" >
                                            <option selected value="">{"Все роли"}</option>
                                            <option value="ayp">{"АУП"}</option>
                                            <option value="operator">{"Оператор"}</option>
                                        </select>
                                    </div>:<></>}
                                </div>
                            </div>
                        </div>

                        <div className="table_scroll_main">
                        <div className="table_scroll_str table_scroll_str_l"  onMouseEnter={() => { scrollDirection("left") }} onMouseLeave={() => { clearScroll() }}>
                            <img src="img/str_l.svg"/>
                        </div>
                        <div className="table_scroll_str table_scroll_str_r"  onMouseEnter={() => { scrollDirection("right") }} onMouseLeave={() => { clearScroll() }}>
                            <img src="img/str_r.svg"/>
                        </div>
                            <div className="table_scroll table">
                                <div className="css_table">
                                    <div className="css_thead">
                                        <div className="css_tr">
                                            <div className="css_th">Период<br /> начисления</div>
                                            <div className="css_th">Оператор</div>
                                            <div className="css_th">Роль <br /> оператора</div>
                                            <div className="css_th">Ответственный</div>
                                            {useCases?.authUseCase.IsAdminUser()?<div className="css_th">Банк</div>:<></>}
                                            <div className="css_th">Дата <br />окончания ИС</div>
                                            <div className="css_th">Срок <br /> работы</div>
                                            <div className="css_th">Ставка, <br /> руб.</div>
                                            <div className="css_th">Аванс <br /> Обучение, <br /> руб.</div>
                                            <div className="css_th">Отраб-ые<br /> часы</div>
                                            {useCases?.authUseCase.IsAdminUser()
                                            || useCases?.authUseCase.IsHeadOfContactCenter()
                                            || useCases?.authUseCase.IsHeadOfGroup()
                                                ?<div className="css_th">Оклад, <br/> руб.</div>
                                                :<></>}
                                            <div className="css_th">изменён</div>
                                            <div className="css_th">Премия <br /> основная, <br /> руб.</div>
                                            <div className="css_th">Премия <br /> Табло, <br /> руб.</div>
                                            <div className="css_th">Премия <br /> группа, <br /> руб.</div>
                                            <div className="css_th">Штрафы, <br /> руб.</div>
                                            <div className="css_th">Итого <br /> начислено, <br /> руб.</div>
                                            <div className="css_th">Аванс, <br /> руб.</div>
                                            <div className="css_th">Итого выплачено <br /> (минус аванс), <br /> руб.</div>
                                            <div className="css_th">Остаток, <br /> руб.</div>
                                        </div>
                                    </div>
                                    <div className="css_tbody">
                                        {
                                            paymentsItems.map((payment, index) => {
                                                return <TableRowNew payment={payment} last={(paymentsItems?.length - 1 == index) && index != 0 ? true : false} updateReportItemsPaymentItem={updateReportItemsPaymentItem} />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {importModalShow? <ImportModal close={()=>{setImportModalShow(false);}} closeUpdate={()=>{
                setImportModalShow(false);
                setPaymentsItems([])
                useCases?.reportUseCase.ReadReport(form.from, form.to, form.operatorId, form.bank, form.role).then((response) => {
                    if (response instanceof Error) {
        
                    } else {
                        setPaymentsItems(response!.payments!)
                    }
        
                })
            }} /> : <></>}
        </div>

    )

}

const TableHeaderWithSorting = ({ title, param, setSortingValue, active }: { title: string, param: string, setSortingValue: (input: string) => void, active: boolean }) => {
    return <th style={{ cursor: "pointer", display: "flex", alignItems: "center", width: "100%" }} onClick={() => { setSortingValue(param) }}>{title.indexOf("<br />") !== -1 ? <Fragment>{title.split("<br />")[0]}<br /> {title.split("<br />")[1]}</Fragment> : title} <div style={{ display: "flex", flexDirection: "column", marginLeft: 5 }}><ChevronUp size={15} color={active ? "black" : "grey"} /> <ChevronDown size={15} color={!active ? "black" : "grey"} /></div></th>
}

const TableHeaderWithSortingNew = ({ title, param, setSortingValue, active }: { title: string, param: string, setSortingValue: (input: string) => void, active: boolean }) => {
    return <div className="css_th">
        <div className="css_th_str">
            {title.indexOf("<br />") !== -1 ? <Fragment>{title.split("<br />")[0]}<br />{title.split("<br />")[1]}</Fragment> : title}
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }} onClick={() => { setSortingValue(param) }}>
                <ChevronUp size={15} color={active ? "black" : "grey"} /> <ChevronDown size={15} color={!active ? "black" : "grey"} />
            </div>
        </div>
    </div>


}